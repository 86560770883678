var _pevents  = null;


angular.module('scoreModals')
.service('popups',
  ['$rootScope', '$q', '$timeout', function ($rootScope,$q,$timeout){
    var popup = {
                  id: null,
                  deferred: null,
                  target: null,
                  model: null,
                  hash: null,
                };

     // Return the public API.
    return({
        open: open,
        popupId: function(){return popup.id;},        
        target: function(){return popup.target;},
        model: function(){return popup.model;},
        popup:function(){return popup.id;},
        resolve: resolve,
        reject: reject
    });

    function open(id, target, model){
      var previousDeferred = popup.deferred;

      if (previousDeferred)
        $rootScope.$emit( "SCORE.popups.close", popup.id );      
      
      popup.id = id;
      popup.deferred = $q.defer();
      popup.target = target;
      popup.model = model;

      $timeout(function(){$rootScope.$emit( "SCORE.popups.open", id );});      
      return( popup.deferred.promise );
    }


    function resolve(response){

      if ( ! popup.deferred ) {
        return;
      }

      var id = popup.id;
      var target = popup.target;
      popup.deferred.resolve(response);
      popup.deferred = popup.target = popup.model = popup.id = null;
      $rootScope.$emit( "SCORE.popups.close", {id:id,target:target});
    }

    function reject(reason) {
      if ( ! popup.deferred ) {
        return;
      }

      var id = popup.id;
      var target = popup.target;
      popup.deferred.reject(reason);
      popup.deferred = popup.target = popup.model = popup.id = null;
      $rootScope.$emit( "SCORE.popups.close", {id:id,target:target});      
    }
  }]
)
.directive('n2sPopupTrigger', 
    ['$rootScope', 'popups', function ($rootScope,popups){

      var link = function(scope,element,attr){

        if (attr.callback && !attr.callback.match(/\(\s*response\s*\)/))
          console.warn("Defined callback for popup [" + attr.callback + "] must be defined with the 'response' parameter (e.g. mycallback(response))");


        element.bind('click',function(){
          if (attr.disabled === undefined || !attr.disabled)
            scope.$apply(scope.open);
        })

        scope.open = function(){


          if (popups.target() == element)
            return;

          var promise = popups.open(scope.id, element,scope.data);        

          promise.then(
            function(response){
              //console.log("RESOLVED",response);
              
              if (scope.callback)
                scope.callback({'response':response});
            },
            function(error){             
              //console.log("REJECTED",error);
            }
          )
        }
      };
  
      return {
        restrict: 'A',
        link:link,
        scope:{
          id:'@n2sPopupTrigger',
          data:'=?n2sPopupData',
          callback:'&?callback'
        }
      };
}])
.directive('n2sPopup', 
    ['$rootScope', '$compile', '$timeout', 'popups', function ($rootScope,$compile,$timeout,popups){

      var compile = function(scope,element,attr){
        var arrow;

        if (attr.arrow == 'left' || attr.arrow == 'right')
          arrow  = $compile( "<div class='n2s-popup-arrow' ng-class='arrowClass' ng-style='{\"margin-top\":arrowOffset}'></div>" )( scope );
        else
          arrow  = $compile( "<div class='n2s-popup-arrow' ng-class='arrowClass' ng-style='{\"margin-left\":arrowOffset}'></div>" )( scope );
        
        element.append( arrow );
      };

      var link = function(scope,element,attr,ngCtrl){

        scope.popup = null;
        scope.arrowOffset = 0;
        scope.arrowPlacement = attr.arrow;
        scope.arrowClass = 'arrow-top';
        scope.closeOnClick = true;

        $rootScope.$on(
            "SCORE.popups.open",
            function handleModalOpenEvent( event, popup ) {
                if (popup == attr.popupId){  

                  scope.$eval(attr.popupInit);
                  $timeout(function(){scope.place()});

                  $(element).on('mousedown',scope.preventClose);
                  $(popups.target()).on('mousedown',scope.preventClose);

                  $(window).off('mousedown',_pevents);

                  _pevents = scope.close;
                  
                  $(window).on('mousedown',scope.close);
                  $(window).on('keydown',scope.keydownHandler);               
                }
            }
        );

        scope.preventClose = function(event){          
          //event.preventDefault();
          scope.closeOnClick = false;
        }

        scope.keydownHandler = function(event){
          if(event.keyCode == 27){            
            popups.reject('ESCAPE');       
          }
        }

        scope.close = function(event){
          /*  
          console.log($(element)[0], $(event.target)[0]);
          console.log($.contains($(element)[0], $(event.target)[0]));
          */

          if (scope.closeOnClick && !$.contains($(element)[0], $(event.target)[0]))
            popups.reject('MOUSEDOWN');

          scope.closeOnClick = true;
        }
       
        $rootScope.$on(
            "SCORE.popups.close",
            function handleModalCloseEvent( event, popupAndTarget ) {

              if (!popupAndTarget.id || popupAndTarget.id == attr.popupId){  
                scope.popup = null;
                element.css('top','');
                element.css('left','');            
                element.css('opacity',0);
                element.removeClass('animated popupIn');
              
                $(element).off('mousedown',scope.preventClose);
                $(popupAndTarget.target).off('mousedown',scope.preventClose);

                $(window).off('mousedown',_pevents);
                //$(window).off('mousedown',scope.close);
                $(window).off('keydown',scope.keydownHandler);

                scope.$eval(attr.popupClose);
              }
            }
        );

        scope.place = function(){

          var arrowPlacement = (scope.arrowPlacement === undefined) ? 'top' : scope.arrowPlacement;
          var arrowHeight = 10;
          var arrowCenter = 11;
          var decoratorMargin = 5;
          var target = popups.target();
          var targetPosition = scope.correctedTargetCoordinates();
          var targetParent = target.offsetParent();
          var targetSize = target[0].getBoundingClientRect();

          var popupWidth = element.outerWidth();                  
          var popupHeight = element.outerHeight();
          var container = element.offsetParent();
          var containerWidth = container.width();                 
          var containerHeight = (container.height()) ? container.height() : $(window).height();

          var left,top, targetSpot;

          if (arrowPlacement == 'top' || arrowPlacement == 'bottom'){

            targetSpot = {left: targetSize.width/2 + targetPosition.left, top:targetPosition.top + targetSize.height};

            if ((targetSpot.top + popupHeight + arrowHeight > containerHeight 
             && targetPosition.top - popupHeight - arrowHeight > decoratorMargin 
             && scope.arrowPlacement === undefined) || arrowPlacement=='bottom'){
              targetSpot.top = targetPosition.top;
              arrowPlacement = 'bottom';
            }

            left = targetSpot.left - popupWidth / 2;

            if (left < decoratorMargin){
              left = decoratorMargin;
            }

            var right = left + popupWidth;
            var rightLimit = containerWidth - decoratorMargin;

            if (right > (rightLimit - 25)){
              left = rightLimit - popupWidth - 25;
            }
            
            scope.arrowOffset = targetSpot.left - left - arrowCenter;

            if (scope.arrowOffset < decoratorMargin)
              scope.arrowOffset = decoratorMargin;

            if (scope.arrowOffset > popupWidth - 2 * arrowCenter - decoratorMargin)
              scope.arrowOffset = popupWidth - 2 * arrowCenter - decoratorMargin;

          }
          else{

            targetSpot = {left: targetSize.width + targetPosition.left, top:targetPosition.top + targetSize.height/2};

            if ((targetSpot.left + popupWidth + arrowHeight > containerWidth
             && targetPosition.left - popupWidth - arrowHeight > decoratorMargin) || arrowPlacement=='right'){
              targetSpot.left = targetPosition.left;
              arrowPlacement = 'right';
            }

            top = targetSpot.top - popupHeight / 2;

            if (top < decoratorMargin){
              top = decoratorMargin;
            }

            var bottom = top + popupHeight;
            var bottomLimit = containerHeight - decoratorMargin;

            if (bottom > bottomLimit){
              top = bottomLimit - popupHeight;              
            }
            
            scope.arrowOffset = targetSpot.top - top - arrowCenter;

            if (scope.arrowOffset < decoratorMargin)
              scope.arrowOffset = decoratorMargin;

            if (scope.arrowOffset > popupHeight - 2 * arrowCenter - decoratorMargin)
              scope.arrowOffset = popupHeight - 2 * arrowCenter - decoratorMargin;
          }

          switch (arrowPlacement){
            case 'top':
              top = targetSpot.top + arrowHeight;
            break;

            case 'bottom':
              top = targetSpot.top - popupHeight - arrowHeight;
            break;

            case 'left':
              left = targetSpot.left + arrowHeight;
            break;

            case 'right':
              left = targetSpot.left - popupWidth - arrowHeight;
            break;

          }

          scope.arrowClass = 'arrow-' + arrowPlacement;
          element.css('left',left);            
          element.css('top',top);
          element.css('opacity',1);
          element.toggleClass('animated popupIn');

          element.find('[tabindex=1]').focus();
        }


        scope.correctedTargetCoordinates = function(){        
           var targetParent = popups.target().offsetParent().offset();
           var container = element.offsetParent().offset();
           var position = popups.target().position();

           return {top:position.top + targetParent.top-container.top,left: position.left + targetParent.left-container.left}; 
        }
      };

     return {
        restrict: 'E',
        require: 'ngController',
        compile: function(){return {pre:compile,post:link};}           
      }; 
}]);

angular.module('scoreModals')
.controller('PopupController',
  ['$scope','popups','$timeout',
    function ($scope,popups,$timeout){

      $scope.model=null;
      $scope.reject = popups.reject;
      $scope.resolve = popups.resolve;

      $scope.onWindowResize = function(){
        $scope.$apply($scope.reject());
        $(window).off('resize',$scope.onWindowResize)
      }

      $scope.onEnter = function(event){
        if (event.keyCode == 13){
          event.stopPropagation();
          $scope.resolve($scope.model);
        }

        $(window).off('keyup',$scope.onEnter);
      }

      $scope.init = function(closeOnEnter){
        $scope.target = popups.target();        
        $scope.model = popups.model();

        var nanoscroller = $('[popup-id="' + popups.popupId() + '"]').find('.nanoscroller');
        nanoscroller .nanoScroller({flash:true});
        nanoscroller .nanoScroller({preventPageScrolling: true});
        nanoscroller .nanoScroller({scroll:'top'});

        $(window).on('resize',$scope.onWindowResize);
        
        if (closeOnEnter)
          $(window).on('keyup',$scope.onEnter);
      }

      $scope.refreshScroll = function(){
        $timeout(function(){
            var nanoscroller = $('[popup-id="' + popups.popupId() + '"]').find('.nanoscroller');
            nanoscroller .nanoScroller({flash:true});
            nanoscroller .nanoScroller({preventPageScrolling: true});
            nanoscroller .nanoScroller({scroll:'top'});
        });
      }

      this.init = $scope.init;
    }
]);
