angular.module('scoreLoginApp', ['ajaxModule', 'scoreFilters', 'scoreModals']).config(['$interpolateProvider', '$httpProvider', function($interpolateProvider, $httpProvider) {
    $interpolateProvider.startSymbol('[[');
    $interpolateProvider.endSymbol(']]');
}])
.directive('equalTo',function() {
    return {
        restrict:'A',
        require: "ngModel",
        scope: {
            otherModelValue: "=equalTo"
        },
        link: function(scope, element, attributes, ngModel) {
             
            ngModel.$validators.equalTo = function(modelValue) {
                return modelValue == scope.otherModelValue;
            };
 
            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
}
)
.controller('LoginController', ['$scope', '$http', '$window', 'popups', function($scope,$http,$window, popups) {

	$scope.view = 'login';
	$scope.model = {};
	$scope.model.password = "";
	$scope.model.repeat_password = "";
	$scope.error = "";
	$scope.errorClass = "login-error";
	$scope.loading = 0;
	$scope.disable = 0;
	$scope.completed = 1;
	$scope.notifications = null;


	$scope.init = function(notifications, tenant, message){
		$scope.notifications = notifications;
		$scope.tenant = tenant;
		$scope.message = message;
	}
	
	$scope.showView = function(view){
		$scope.message = null;
		$scope.view = view;
	}

	$scope.resetPasswd = function(){
		$scope.message =  "";
		var promise;

		promise = $http({method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded'}, url: 'resetPassword',data:$scope.model});

		promise
			.then(function(result) {
				$scope.model.email = "";
				$scope.showView('email');
			})
			.catch(function(result) {
				$scope.message = result.data.msg;
			});			
	}	  
 }])
.controller('ResetPasswordController', ['$scope', '$http', '$window', function($scope,$http,$window) {

	$scope.model = {password:'',passwordAgain:''};
	$scope.error = "";
	$scope.errorClass = "login-error";
	$scope.loading = 0;
	//$scope.passwordStrengthDefault = {score:0};
	$scope.passwordStrengthSecurity = 0;
	$scope.waitingData = false;
	$scope.repeatedPassword = false;

	$scope.init = function(tenant, userId, groupsIds, hasSecurity, securityProperties, token, message){
		$scope.tenant = tenant;
		$scope.message = message;
		$scope.groupsIds = groupsIds;
		$scope.hasSecurity = hasSecurity;
		$scope.securityProperties = securityProperties;
		$scope.model.id = userId;
		$scope.model.token = token;
	}

	$scope.showView = function(view){
		$scope.message = null;
		$scope.view = view;
	}

	$scope.$watch('model.password',function(){
		if ($scope.model.password) {
			if ($scope.repeatedPassword) {
				$scope.repeatedPassword = false;
				$scope.message = '';
			}
			//$scope.passwordStrengthDefault = zxcvbn($scope.model.password);
			$scope.passwordLevel($scope.model.password);
		}
	});

	$scope.isValid = function() {
		return (
			$scope.resetPasswd.$valid && !$scope.waitingData &&
			$scope.passwordValid()
		);
	};

	$scope.passwordValid = function() {
		if ($scope.model.password || $scope.model.passwordAgain) {
			if ($scope.hasSecurity > 0) {
				return ($scope.passwordStrengthSecurity > 2)
			} else {
				//return ($scope.passwordStrengthDefault.score > 2);
				return true;
			}
		}

		return false;
	};

	$scope.passwordLevel = function(pass) {

		if ($scope.hasSecurity > 0) {
			$scope.waitingData = true;

			var promise = $http({
				method: 'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'},
				url: '/check_password',
				data: {groupsIds : $scope.groupsIds, password : pass}
			});

			promise.then( function (response) {
				$scope.passwordStrengthSecurity = response.data;
				$scope.waitingData = false;
			}).catch( function (response) {
				if (response.status == 422){
					$scope.passwordStrengthSecurity = response.data;
				} else {
					$scope.passwordStrengthSecurity = 0;
				}
				$scope.waitingData = false;
			});
		} else {
			$scope.passwordStrengthSecurity = 0;
		}
	}

	$scope.generatePassword = function(){

		$scope.loading = 1;

		if($scope.model.password == $scope.model.passwordAgain){

			var promise = $http({method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded'}, url: 'generatePassword',data:$scope.model});

			promise
				.then(function(result) {
					$window.location.href ='logout';
				})
				.catch(function(result) {
					if (result.status == 422){
						$scope.repeatedPassword = true;
						$scope.model.password = '';
						$scope.model.passwordAgain = '';
						$scope.message = result.data.msg;
					}else {
						$scope.message = result.data.msg;
					}
				});
		}
	}
}])
.controller('DoubleFactorController', ['$scope', '$http', '$window', function($scope,$http,$window) {
	$scope.view = 'sendCode';
	$scope.model = {doubleFactorMethod:'mail'};
	$scope.error = "";
	$scope.errorClass = "login-error";
	$scope.sendError = false;
	$scope.loading = 0;
	$scope.codeError = '';
	$scope.maxAttemps = false;

	$scope.init = function(tenant, userId, smsConfigId, message){
		$scope.tenant = tenant;
		$scope.message = message;
		$scope.smsConfigId = smsConfigId;
		$scope.model.id = userId;
		$scope.maxAttemps = false;
	}

	$scope.showView = function(view){
		$scope.message = null;
		$scope.codeError = '';
		$scope.view = view;
	}

	$scope.sendCode = function(){

		$scope.message =  "";
		$scope.model.smsConfigId = (!$scope.smsConfigId) ? 0 : $scope.smsConfigId;
		$scope.sendError = false;
		var promise;

		promise = $http({method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded'}, url: '/2fa_send',data:$scope.model});

		promise
			.then(function(result) {
				$scope.showView('confirmCode');
				$scope.maxAttemps = false;
			})
			.catch(function(result) {
				$scope.sendError = true;
				$scope.message = result.data.msg;
			});
	}

	$scope.confirmCode = function(){
		$scope.message =  "";
		var promise;

		promise = $http({method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded'}, url: '2fa_precheck',data:{code:$scope.model.token}});

		promise
			.then(function(result) {
				$scope.codeError = '';
				document.getElementById("checkCodeForm").submit();
			})
			.catch(function(result) {;
				$scope.model.token = '';
				$scope.codeError = result.data.msg;
				if ((result.status == 403) || (result.status == 408)) {
					$scope.maxAttemps = true;
				}
			});
	}

	$scope.resendCode = function(){
		location.href = "/2fa";
	}
}])
.controller('unsubscribeMonthlyReportController', ['$scope', '$http', '$window', function($scope,$http,$window) {
	$scope.error = "";

	$scope.init = function(tenant){
		$scope.tenant = tenant;
	}

	$scope.showView = function(view){
		$scope.message = null;
		$scope.view = view;
	}
}])
.controller('NotificationPanelModalController',['$scope', '$sce', '$rootScope', '$http', '$window', '$timeout', '$controller', function($scope, $sce, $rootScope, $http, $window, $timeout, $controller){

	var modalController = $controller('ModalController',{$scope:$scope});


	$scope.ninit = function(lang, notifications){

			var datos = angular.copy(notifications);

			for(i in datos){
				datos[i].title = (!datos[i].title[lang]) ? datos[i].title['default'] : datos[i].title[lang];
				datos[i].description = (!datos[i].description[lang]) ? datos[i].description['default'] : datos[i].description[lang];
				datos[i].url = (!datos[i].url[lang]) ? datos[i].url['default'] : datos[i].url[lang];
			}
			if (datos.length == 0){
				$('#noNotification').show();
			}else{
				$('#noNotification').hide();
			}
			$scope.items = datos;
			$timeout(function(){
				var scrollers = $('#notificationPanelTable');
				scrollers.nanoScroller({preventPageScrolling:true});
				scrollers.nanoScroller({flash:true});
				scrollers.nanoScroller({scrollTop:0});
			});


		}
		$scope.renderHtml = function(html_code)
		{
			return $sce.trustAsHtml(html_code);
		};
	}]
);

angular.module('ajaxModule', [], ['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
   
    /**
     * The workhorse; .
     * @param {Object} obj
     * @return {String}
     */      
    var param = function(obj) {
      var query = '', name, value, fullSubName, subName, subValue, innerObj, i;
        
      for(name in obj) {
        value = obj[name];
          
        if(value instanceof Array) {
          for(i=0; i<value.length; ++i) {
            subValue = value[i];
            fullSubName = name + '[' + i + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += param(innerObj) + '&';
          }
        }
        else if(value instanceof Object) {
          for(subName in value) {
            subValue = value[subName];
            fullSubName = name + '[' + subName + ']';
            innerObj = {};
            innerObj[fullSubName] = subValue;
            query += param(innerObj) + '&';
          }
        }
        else if(value !== undefined && value !== null)
          query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
      }
        
      return query.length ? query.substr(0, query.length - 1) : query;
    };
   
    // Override $http service's default transformRequest
    $httpProvider.defaults.transformRequest = [function(data) {
      return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
    }];
  }]);

