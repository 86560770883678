angular.module('scoreFilters', [])
.filter("asDate", function () {
    return function (input) {
        if (!input)
            return null;
        
        var date = new Date(NaN);
        var isoExp = /^^s*(\d{4})-(\d\d)-(\d\d)\s*((\d\d):(\d\d)(:(\d\d))?)?/;
        var parts = isoExp.exec(input);

        if (parts){

        	month = +parts[2];
        	date.setFullYear(parts[1],month-1,parts[3]);
        	if (month != date.getMonth() + 1){
        		date.setTime(NaN);
        	}

            if (parts[5]){
                date.setHours(parts[5]);
                date.setMinutes(parts[6]);
            
                if (parts[8])
        	       date.setSeconds(parts[8]);
            }else{
               date.setHours(0); 
               date.setMinutes(0); 
               date.setSeconds(0); 
            }

        }

        return date.getTime();
    }
});