// CSS
import './../../css/login.scss';
import './../../css/fonts.scss';
import './../../css/css3-animations.scss';
import './../../css/nanoscroller.scss';

// JS
import angular from 'angular'
window.angular = angular;

import '../Angular/Controller/LoginController.js'
import '../Angular/Filters/asDate.js'
import '../Angular/scoreModals.js'
import '../Angular/Directives/Popup/Popup.js'

import '../jQuery/jquery.nanoscroller.min.js';